import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { globalVar } from '~/utils/globalVar'
const currencyJs = require('currency.js')

if (!Vue.__global_mixin_) {
    Vue.__global_mixin_ = true
    Vue.mixin({
        layout(context) {
            const appTheme = context.$config.THEME
            const themes = globalVar.themeList
            if (appTheme && themes.includes(appTheme)) {
                return appTheme
            }
            return globalVar.themes.theme2.value
        },
        computed: {
            ...mapState({
                playerBalanceBySocket: (state) =>
                    state.player.totalBalanceFromSocket,
                totalBalance: (state) => state.player.totalBalance,
                playerTotalBalance: (state) => state.player.player.balance,
                themesLanguageString: (state) =>
                    state.themes['language-string'].languageStrings,
                cancelToken: (state) => state.settings.cancelToken,
                isInternational: (state) =>
                    state.themes.settings.isInternational,
                supportCountryAndLanguage: (state) =>
                    state.settings.supportCountryAndLanguage,
                playerInfo: (state) => state.player.player,
                baseSettingConfiguration: (state) =>
                    state['base-settings'].configuration,
                baseSettingValidation: (state) =>
                    state['base-settings'].validation,
            }),
            ...mapGetters({
                exchangeRatesWithCurrency:
                    'themes/settings/exchangeRatesWithCurrency',
                bankListWithName: 'banks/bankListWithName',
            }),
            globeExchangeRate() {
                if (!this.$cookie.get('currency')) return []
                return this.exchangeRatesWithCurrency(
                    this.$cookie.get('currency')
                )
            },
            isMainWebsite() {
                return ['main', 'custom', 'alternate'].includes(
                    this.baseSettingConfiguration?.domain_type
                )
            },
            banksList() {
                // Bank getter, add a new Key into the bank list
                return this.bankListWithName(this.handleCookie('wl_locale'))
            },
            bankListAndManualOptions() {
                return this.$store.state.banks.bankOptionsList.map((el) => {
                    return {
                        ...el,
                        text: this.tl(el.text),
                    }
                })
            },
            requestApp() {
                if (!this.$config.APK) return false
                return (
                    this.$config.APK !== 'false' && this.$config.APK !== false
                )
            },
        },
        methods: {
            async logout() {
                this.$store.dispatch('settings/toggleIsLogin', false)
                await this.$store.dispatch('auth/logout')

                // By checking this condition, we avoid unnecessary redirects and prevent any unintended side effects,
                // such as additional history entries or redundant routing operations.
                if (this.$route.path !== '/') {
                    await this.$router.replace({ path: '/' })
                }
                this.$router.go(0)
            },
            typingSpecialNumbers(event) {
                const notAllowDash = /^[0-9.,]/g
                if (
                    !notAllowDash.test(event.key) &&
                    event.key !== 'Backspace' &&
                    event.key !== 'Tab' &&
                    event.key !== 'ArrowLeft' &&
                    event.key !== 'ArrowRight'
                ) {
                    event.preventDefault()
                }
            },
            copy(content, success = true) {
                if (navigator.clipboard) {
                    navigator.clipboard.writeText(content)
                } else {
                    const dummy = document.createElement('textarea')
                    dummy.value = content
                    dummy.style.top = '0'
                    dummy.style.left = '0'
                    dummy.style.position = 'fixed'
                    dummy.style.zIndex = -1
                    document.body.appendChild(dummy)
                    dummy.focus()
                    dummy.select()
                    dummy.setSelectionRange(0, 99999)
                    document.execCommand('copy')
                    document.body.removeChild(dummy)
                }
                if (success) {
                    this.$toast.success(this.tl('copiedToClipboard'))
                } else {
                    this.$toast.show(this.tl('contentCopied'))
                }
            },
            customToFixedTrunc(x, n) {
                const v = (typeof x === 'string' ? x : x.toString()).split('.')
                if (n <= 0) return v[0]
                let f = v[1] || ''
                if (f.length > n) return `${v[0]}.${f.substr(0, n)}`
                while (f.length < n) f += '0'
                return `${v[0]}.${f}`
            },
            playerRealtimeBalance() {
                return (
                    this.playerBalanceBySocket ||
                    this.totalBalance ||
                    this.playerTotalBalance ||
                    '0'
                )
            },
            tl(code) {
                return this.themesLanguageString[code] || code
            },
            dayJS(date) {
                if (!date) {
                    return '-'
                }
                return this.$dayjs(date).format('DD-MM-YYYY HH:mm:ss')
            },
            formatCurrencyJS(value, removeDecimal = false, precision = 2) {
                if (!value) value = 0
                const response = currencyJs(parseFloat(value), {
                    separator: ',',
                    symbol: '',
                    precision,
                }).format()
                if (removeDecimal) {
                    // This precision is for exchange rates purposes only
                    if (precision === 3) return response.replace('.000', '')
                    return response.replace('.00', '')
                }
                return response
            },
            isMobileOnly() {
                return this.$store.state.themes.settings.isMobile
            },
            parseIntNumber(val) {
                try {
                    return Number.parseInt(val)
                } catch (error) {
                    return false
                }
            },
            // show the current locale text
            // if current locale not exist, show english instead
            getLocaleLang(item, forceLocale = false) {
                if (!item) return null
                if (forceLocale) {
                    return item[this.handleCookie('wl_locale')]
                }
                if (item[this.handleCookie('wl_locale')])
                    return item[this.handleCookie('wl_locale')]
                else return item['en-US']
            },
            getVisibleItems(items) {
                if (!items || typeof items !== 'object' || !items[0]) return []
                return items?.filter((item) => item?.is_visible)
            },
            doCancelAxiosRequest() {
                for (const key in this.cancelToken) {
                    if (this.cancelToken[key]) {
                        this.cancelToken[key].cancel('')
                        delete this.cancelToken[key]
                    }
                }
            },
            async globalBrowserDefaultLanguage() {
                const countryCode = this.$cookie.get('country_code')
                const data = this.supportCountryAndLanguage.find(
                    (el) => el.country_code === countryCode
                )
                const supportedLanguages = data?.supported_languages

                if (supportedLanguages.length > 0) {
                    // Check if the browser language is matched to one of the supported languages
                    const browserLanguage = navigator?.language
                    const browserPrefixLanguageTag = browserLanguage?.substring(
                        0,
                        2
                    )
                    // const browserPrefixLanguageTag = 'zh'

                    // Find the language which starting with the same browser Prefix Language Tag
                    let findMatchBrowserLanguage = null
                    if (browserPrefixLanguageTag) {
                        findMatchBrowserLanguage = supportedLanguages.find(
                            (el) => {
                                if (
                                    el.language.includes(
                                        browserPrefixLanguageTag
                                    ) &&
                                    el.is_active
                                ) {
                                    return true
                                }
                                return false
                            }
                        )
                    }
                    // Find the browser Prefix Language is match, then use only browser language
                    let matchedLocale = null
                    if (findMatchBrowserLanguage) {
                        matchedLocale = findMatchBrowserLanguage.language
                    } else {
                        const findIsActiveLanguage = supportedLanguages.find(
                            (el) => el.is_active && el.is_default
                        )
                        if (findIsActiveLanguage) {
                            matchedLocale = findIsActiveLanguage.language
                        } else {
                            matchedLocale = supportedLanguages[0].language
                        }
                    }

                    this.$store.dispatch(
                        'base-settings/updateLocaleAndSetCookie',
                        matchedLocale
                    )
                    await this.$store.dispatch(
                        'themes/language-string/fetchLanguageStrings',
                        matchedLocale
                    )
                    await this.$store.dispatch('cms/cms', {})
                }
            },
            /**
             * Handles cookie operations (get and set) based on the provided parameters.
             * @param {string} key - The key/name of the cookie to interact with. This is required.
             * @param {string|null} value - The value to set for the cookie. If null or omitted, the function will retrieve the cookie with the given key.
             * @param {object} [option={}] - Cookie options
             */
            handleCookie(key, value = null, option = {}) {
                if (!key) return

                const validationRegex = this.baseSettingValidation[key]
                const storedValue =
                    this.baseSettingConfiguration[key] || this.$cookie.get(key)

                if (value === null) {
                    if (!validationRegex || validationRegex.test(storedValue)) {
                        return storedValue
                    }
                    return
                }

                if (validationRegex && !validationRegex.test(value)) {
                    return
                }
                // Set cookie into cookie and state cookie into state
                this.$cookie.set(key, value, {
                    path: '/',
                    secure: true,
                    ...option,
                })
                this.$store.commit('base-settings/setConfiguration', {
                    locale: value,
                })
            },
            async initSocketTheme(theme = globalVar.themes.theme2.value) {
                this.socket = await this.$nuxtSocket({
                    reconnection: false,
                    auth: {
                        Authorization: `Bearer ${this.$cookie.get('token')}`,
                    },
                    transports: ['websocket'],
                })
                this.socket.on('disconnect', (e) => {
                    this.socket.connect()
                })

                // Backend team need this callback
                this.socket.on('ws-ping', (payload, callback) => {
                    try {
                        // eslint-disable-next-line n/no-callback-literal
                        callback('ykx52280')
                    } catch (_) {}
                })

                let reconnectCount = 0
                this.socket.on('connect_error', (e) => {
                    //  in case token is expired
                    if (typeof e === 'object') {
                        const message = e?.message
                        try {
                            const messageObject = JSON.parse(message)
                            if (messageObject?.code === 401) {
                                this.logout()
                            }
                        } catch (_) {}
                    }
                    const MAX_RECONNECTION_FAIL_COUNT = 1000
                    const TIME_INTERVAL_TO_RECONNECT = 1000

                    if (reconnectCount < MAX_RECONNECTION_FAIL_COUNT) {
                        setTimeout(() => {
                            this.socket.connect()
                        }, TIME_INTERVAL_TO_RECONNECT)
                        reconnectCount++
                    }
                })

                this.socketPlayerBalance()
                this.socketDeposit()
                this.socketWithdrawal(theme)

                // Subscribe Store, Socket Only
                this.socketTransactionHistory()
            },
            socketPlayerBalance() {
                this.socket.on('playerBalance', (msg) => {
                    this.$store.dispatch('player/totalBalanceSocket', msg)
                })
                this.socket.on('onHoldPlayerBalance', (msg) => {
                    this.$store.dispatch('player/setHoldBalance', msg.balance)
                    this.$store.commit('sockets/setShowHoldBalance', true)
                })
            },
            socketDeposit() {
                this.socket.on('successfulDeposit', (msg) => {
                    this.$store.commit('sockets/setDisplayGetBonusDetail', msg)
                    this.$store.commit('sockets/setDisplayGetBonusModal', true)
                })
            },
            socketWithdrawal(theme) {
                this.socket.on('lockWithdrawal', async (msg) => {
                    if (!this.$root?.withdrawExistChange) return
                    const result = await this.$store.dispatch(
                        'themes/withdraw/checkExistWithdrawal'
                    )
                    this.$root.withdrawExistChange(result)
                })
                this.socket.on('revokeWithdrawal', async (msg) => {
                    if (theme === globalVar.themes.theme3.value) {
                        this.$store.commit('sockets/setWithdrawalStatus', false)
                        this.$store.commit(
                            'sockets/setDisplayWithdrawalSocket',
                            true
                        )
                    }
                    if (!this.$root?.withdrawExistChange) return
                    const result = await this.$store.dispatch(
                        'themes/withdraw/checkExistWithdrawal'
                    )
                    this.$root.withdrawExistChange(result)
                })
                this.socket.on('unlockWithdrawal', async (msg) => {
                    if (!this.$root?.withdrawExistChange) return
                    const result = await this.$store.dispatch(
                        'themes/withdraw/checkExistWithdrawal'
                    )
                    this.$root.withdrawExistChange(result)
                })
                this.socket.on('successfulWithdrawal', async (msg) => {
                    if (theme === globalVar.themes.theme3.value) {
                        this.$store.commit('sockets/setWithdrawalStatus', true)
                        this.$store.commit(
                            'sockets/setDisplayWithdrawalSocket',
                            true
                        )
                    }
                    if (!this.$root?.withdrawExistChange) return
                    const result = await this.$store.dispatch(
                        'themes/withdraw/checkExistWithdrawal'
                    )
                    this.$root.withdrawExistChange(result)
                })
            },
            socketTransactionHistory() {
                this.$store.subscribeAction((mutation, state) => {
                    // Confirm Deposit
                    if (
                        mutation.type === 'sockets/revokeWithdrawal' ||
                        mutation.type === 'sockets/successfulWithdrawal' ||
                        mutation.type === 'sockets/revokeDepositByAdmin' ||
                        mutation.type === 'sockets/successfulDeposit'
                    ) {
                        if (!this.$root?.isTransactionHistoryPage) return
                        this.$store.dispatch(
                            'themes/transaction/getTransactions',
                            {
                                sort: 'created_at|desc',
                                limit: 10,
                                page: 1,
                                language: this.handleCookie('wl_locale'),
                                start_date: '2022-01-01 00:00:00',
                                end_date: this.$dayjs().format(
                                    'YYYY-MM-DD HH:mm:ss'
                                ),
                            }
                        )
                    }
                })
            },
        },
    })
}
