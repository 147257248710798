
import { mapState, mapGetters } from 'vuex'
import bannerRedirect from '~/mixins/common/banner-redirect'
import baseLogic from '~/mixins/base-logic'
import ServerSide from '~/mixins/serverSide'

export default {
    name: 'Theme2Layout',
    mixins: [baseLogic, ServerSide, bannerRedirect],
    data() {
        return {
            isMobileScreen: false,
        }
    },
    head() {
        const domain = `https://${this.$config.DOMAIN}`
        const fullPath = this.$route.fullPath
        return {
            link: [{ rel: 'canonical', href: `${domain}${fullPath}` }],
            meta: [
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${domain}${fullPath}`,
                },
            ],
        }
    },
    computed: {
        ...mapGetters({
            languages: 'themes/settings/language',
            supportCountries: 'themes/settings/supportCountry',
            banks: 'banks/banks',
        }),
        ...mapState({
            menubars: (state) => state.cms.menus.menus,
            cms: (state) => state.cms.cms,
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
            showHoldBalance: (state) => state.sockets.showHoldBalance,
            displayGetBonusDetail: (state) =>
                state.sockets.displayGetBonusDetail,
            displayGetBonusModalState: (state) =>
                state.sockets.displayGetBonusModal,
            floatingIcons: (state) => state.cms.floatingIcons,
        }),
        displayGetBonusModal: {
            get() {
                return this.displayGetBonusModalState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayGetBonusModal', value)
            },
        },
        navbarComponentName() {
            return this.isMobileScreen
                ? 'Theme2MobileNavBar'
                : 'Theme2DesktopNavBar'
        },
        footerComponentName() {
            return this.isMobileScreen
                ? 'Theme2MobileFooterComponent'
                : 'Theme2DesktopFooter'
        },
    },
    watch: {
        $route(to, from) {
            if (!this.$cookie.get('visit') && !this.isInternational)
                this.$store.dispatch('themes/settings/fetchVisitWebsite')
        },
    },
    created() {
        this.$vuetify.theme.dark = true
    },
    async mounted() {
        this.$store?.dispatch('app/checkIsApp')

        try {
            this.$cookie.set('previous_url', document.referrer)
        } catch (error) {}

        this.showBanner()
        this.$store.dispatch('player/getPlayerInviteAndEarnDomain') // Get invite and earn domain link (refer-friend page)
        this.getFaviconIcon()

        // Check previous language setting
        if (this.$cookie?.get('is_change_language')) {
            this.$store.commit('settings/changeLanguageLoading', true)
        }

        // Local settings
        const locale = this.handleCookie('wl_locale')
        if (locale) {
            this.$recaptcha.language = locale
        }

        // Check session
        if (!window.sessionStorage.getItem('visit')) {
            this.$store.dispatch('themes/settings/fetchVisitWebsite')
            window.sessionStorage.setItem('visit', 'true')
        }

        this.onResize()
        this.loadData()
        await this.initializeSettings()
    },
    methods: {
        onResize() {
            const MOBILE_SCREEN_WIDTH = 1300
            this.isMobileScreen = window.innerWidth < MOBILE_SCREEN_WIDTH
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
        closeHoldBalanceDialog() {
            this.$store.commit('sockets/setShowHoldBalance', false)
        },
        loadData() {
            if (this.menubars.length <= 0) {
                this.$store.dispatch('cms/menus/getMenubars')
            }
            if (this.cms.length <= 0) {
                this.$store.dispatch('cms/cms', {})
            }
        },

        async initializeSettings() {
            await this.$store.dispatch('settings/supportCountryAndLanguage')
            this.$store.dispatch('cms/floatingIcons')
            if (this.$cookie.get('token')) {
                this.initSocketTheme()
                await this.$store.dispatch(
                    'themes/player-bank/getBankPlayerAccount'
                )
            }
            if (!this.languages.length) {
                await this.setDefaultLanguages()
            }
            if (this.$cookie.get('currency')) {
                await this.$store.dispatch(
                    'settings/operationSetting',
                    this.$cookie.get('currency')
                )
            }
            if (!this.supportCountries.length) {
                await this.$store.dispatch('themes/settings/getSupportCountry')
            }
            await this.$store.dispatch('themes/settings/getExchangeRate')
            this.$store.commit('settings/changeLanguageLoading', false)
            this.$cookie.remove('is_change_language')
        },
    },
}
